@font-face {
  font-family: 'Vodafone Rg';
  src: local('VodafoneRg'),
       url(assets/fonts/VodafoneRg.eot) format('embedded-opentype'),
       url(assets/fonts/VodafoneRg.ttf) format('truetype'),
       url(assets/fonts/VodafoneRg.woff) format('woff');
}

@font-face {
  font-family: 'Vodafone Lt';
  src: local('VodafoneLt'),
       url(assets/fonts/VodafoneLt.eot) format('embedded-opentype'),
       url(assets/fonts/VodafoneLt.ttf) format('truetype'),
       url(assets/fonts/VodafoneLt.woff) format('woff');

}

body {
  margin: 0;
  font-family: 'Vodafone Lt', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Vodafone Lt', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
